import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { hotels } from "../helpers/hotels.js";

import Layout from "../components/layout";
import SEO from "../components/seo";

//components
import ImageBackground from "../components/imagebackground";
import settings from "../../settings.js";

//images
const topbannerDesktop = `${settings.IMAGES_BASE_URL}/images/hotelpartners/hotel-banner.jpg`;
const topbannerMobile = `${settings.IMAGES_BASE_URL}/images/hotelpartners/hotel-banner.jpg`;


const southCarolinaHotels = hotels.filter(
  (hotel) => hotel.location === "South Carolina"
);
const californiaHotels = hotels.filter(
  (hotel) => hotel.location === "California"
);
const indianapolisHotels = hotels.filter(
  (hotel) => hotel.location === "Indianapolis"
);


class HotelPartners extends React.Component {
  constructor(props) {
    super(props);

    this.contentRef = null;
  }

  scrollToContent = () => {
    if(!this.contentRef) return;

    this.contentRef.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    return (
      <Layout>
        <SEO
          title="BMW Performance Driving School | Hotel Partners"
          description="Both locations of BMW's Performance Driving School - Spartanburg, South Carolina and Thermal/Palm Springs, California - offer the BMW experience of a lifetime!"
          keywords="bmw driving school greenville sc, bmw driving school near me, bmw driving school thermal, bmw driving school in south carolina, bmw driving school palm springs, bmw driving school experience"
        />
        <div className="page__locations">
          <ImageBackground
            text="You’re going to need some rest"
            imageDesktop={topbannerDesktop}
            imageMobile={topbannerMobile}
            scrollTo={this.scrollToContent}
            imageAlt="BMW m4 Location Top Banner"
            topLeftChildren={
              <div className="text_left">
                <div>YOU’RE GOING TO NEED</div>
                <div>SOME REST.</div>
              </div>
            }
          />
          <section className="location" ref={ (ref) => this.contentRef=ref}>
            <div className="container">
              <div className="location__header">
                <h1>Hotel partners</h1>
                <p>
                  With all that excitement out on the track, you’re going to
                  need a place to recharge. Select one of our hotel partners
                  during your stay, and you’ll enjoy exclusive savings.
                </p>
              </div>
            </div>
          </section>
          <div className="hotelpartners">
            <section id="end-page-sc" className="other-schools">
              <div className="container">
                <h2>HOTEL AFFILIATES - SOUTH CAROLINA</h2>
              </div>
              <div className="other-schools__content row">
                {southCarolinaHotels.map((hotel) => {
                  return (
                    <div className="other-schools__item" key={hotel.id}>
                      <LazyLoadImage
                        effect="blur"
                        src={hotel.image}
                        alt={hotel.imageAlt}
                      />
                      <p>
                        {hotel.name}
                        <br />
                        {hotel.bookingcode && `Code: ${hotel.bookingcode}`}
                      </p>
                      <a href={hotel.URL} target="_blank" rel="noreferrer">
                        <button className="btn btn-bordered">Book now</button>
                      </a>
                    </div>
                  );
                })}
              </div>
            </section>
            <section id="end-page-ca" className="other-schools">
              <div className="container">
                <h2>HOTEL AFFILIATES - CALIFORNIA</h2>
              </div>
              <div className="other-schools__content row">
                {californiaHotels.map((hotel) => {
                  return (
                    <div className="other-schools__item">
                      <LazyLoadImage
                        effect="blur"
                        src={hotel.image}
                        alt={hotel.imageAlt}
                      />
                      <p>
                        {hotel.name}
                        <br />
                        {hotel.bookingcode && `Code: ${hotel.bookingcode}`}
                        {hotel.midweekcode && `Midweek code: ${hotel.midweekcode}`}
                        <br />
                        {hotel.weekendcode && `Weekend 10% code: ${hotel.weekendcode}`}
                      </p>
                      <a href={hotel.URL} target="_blank" rel="noreferrer">
                        <button className="btn btn-bordered">Book now</button>
                      </a>
                    </div>
                  );
                })}
              </div>
            </section>
            <section id="end-page-ind" className="other-schools">
              <div className="container">
                <h2>HOTEL AFFILIATES - INDIANAPOLIS</h2>
              </div>
              <div className="other-schools__content row">
                {indianapolisHotels.map((hotel) => {
                  return (
                    <div className="other-schools__item">
                      <LazyLoadImage
                        effect="blur"
                        src={hotel.image}
                        alt={hotel.imageAlt}
                      />
                      <p>
                        {hotel.name}
                        <br />
                        {hotel.bookingcode && `Code: ${hotel.bookingcode}`}
                      </p>
                      <a href={hotel.URL} target="_blank" rel="noreferrer">
                        <button className="btn btn-bordered">Book now</button>
                      </a>
                    </div>
                  );
                })}
              </div>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}
export default HotelPartners;
