import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'lazysizes';
import classNames from 'classnames'
import 'react-lazy-load-image-component/src/effects/blur.css';
import settings from '../../settings';

const arrow = `${settings.IMAGES_BASE_URL}/images/assets/scroll-arrow-image.svg`;

class ImageBackground extends React.Component {
  state = {
    width: ''
  };
  componentDidMount() {
    this.setState({
      width: window.innerWidth
    })
    window.addEventListener('resize', this.handleWindowSizeChange);
   

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  onScroll = () => {
    if(this.props.scrollTo){
      this.props.scrollTo()
    }
  };

  scrollToRef() {
    window.scrollTo({
      top: 1000,
      left: 0,
      behavior: 'smooth'
    });
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width < 767;
    const showHeader = (!isMobile || (isMobile && !this.props.hideMobileHeader));
    return (
      <section className="hero__header">
        {showHeader ? 
          (this.props.topLeftChildren && (<div className="hero__header_topleft">{this.props.topLeftChildren}</div>)) : ""
        }
        {isMobile ? (
          <img effect="blur" fetchpriority="high" width="400" height="800" data-src={this.props.imageMobile} data-srcset={this.props.srcSet} alt={this.props.imageAlt} className={classNames("img-mobile","lazyload",this.props.imageClassName)} />
        ) : (
          <img effect="blur" fetchpriority="high" src={this.props.imageDesktop}
          srcSet={this.props.srcSet} width="400" height="800" alt={this.props.imageAlt}  className={classNames("img-mobile",this.props.imageClassName)} />
        )}
        <div className="hero__header__content">
          <div className="container">
            {this.text &&  (<h1>{this.props.text}</h1>)}
            {!this.props.hideArrow &&
              <button className="hero__header__arrow_bottom arrow" onClick={this.onScroll} onKeyDown={this.scrollToRef} aria-label="scroll-down">
                <img effect="blur" src={arrow} alt="arrow" className="scroll-arrow lazyload" title="scroll-arrow" />
              </button>
            }
          </div>
        </div>
      </section>
    );
  }
}

export default ImageBackground;
